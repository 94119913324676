<template>
  <div id="currnet-stock-list">
    <v-card>
      <v-card-title>各種マスター</v-card-title>
      <v-tabs
        v-model="masterStock"
        active-class="selectedTab"
        background-color="#EDE7F6"
        slider-color="#fff"
        style="border-style: groove;border-radius: revert !important;"
      >
        <v-tab
          href="#contractStatus"
          @click="loadContractStatus"
        >
          契約状態
        </v-tab>
        <v-tab
          href="#incoterms"
          @click="loadIncotermList"
        >
          取引方式
        </v-tab>
        <v-tab
          href="#destination"
          @click="loadDestinationList"
        >
          目的地
        </v-tab>
        <v-tab
          href="#currency"
          @click="loadCurrencyList"
        >
          通貨
        </v-tab>

        <v-tabs-items
          v-model="masterStock"
          touchless
        >
          <v-tab-item value="contractStatus">
            <ContractStatusList
              ref="contractStatusList"
              @has-mounted="getContractStatusList"
            ></ContractStatusList>
          </v-tab-item>
          <v-tab-item value="incoterms">
            <IncotermsList
              ref="incotermList"
              @has-mounted="getIncotermsList"
            ></IncotermsList>
          </v-tab-item>
          <v-tab-item value="destination">
            <Destination
              ref="destination"
              @has-mounted="getDestinationList"
            ></Destination>
          </v-tab-item>
          <v-tab-item value="currency">
            <Currency
              ref="currency"
              @has-mounted="getCurrencyList"
            ></Currency>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  mapMutations,
} from 'vuex'

import ContractStatusList from './ContractStauts/ContractStautsList.vue'
import IncotermsList from './Incoterms/IncotermsList.vue'
import Destination from './Destination/DestinationList.vue'
import Currency from './Currency/CurrencyList.vue'

export default {
  components: {
    ContractStatusList,
    IncotermsList,
    Destination,
    Currency,
  },
  data: () => ({
    masterStock: 'incoterms',
    ContractStatusListFlag: false,
    IncotermsListFlag: false,
    DestinationFlag: false,
    CurrencyFlag: false,
  }),

  computed: {
  },

  watch: {
    masterStock(newValue) {
      this.$router.push({
        query: {
          selTab: newValue,
        },
      })
    },
  },

  mounted() {
    if (this.$route.query.selTab) {
      this.masterStock = this.$route.query.selTab
    }
    this.loadSelTab()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    sleep(t) {
      const a = new Promise(resolve => {
        setTimeout(() => { resolve() }, t)
      })

      return a
    },

    loadSelTab() {
      if (this.masterStock === 'contractStatus') {
        this.loadContractStatus()
      }
      if (this.masterStock === 'incoterms') {
        this.loadIncotermList()
      }
      if (this.masterStock === 'destination') {
        this.loadDestinationList()
      }
      if (this.masterStock === 'currency') {
        this.loadCurrencyList()
      }
    },

    getContractStatusList() {
      this.ContractStatusListFlag = true
    },
    getIncotermsList() {
      this.IncotermsListFlag = true
    },
    getDestinationList() {
      this.DestinationFlag = true
    },
    getCurrencyList() {
      this.CurrencyFlag = true
    },

    async loadContractStatus() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.ContractStatusListFlag) { await this.sleep(1000) }
      this.$refs.contractStatusList.loadData()
    },
    async loadIncotermList() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.IncotermsListFlag) { await this.sleep(1000) }
      this.$refs.incotermList.loadData()
    },
    async loadDestinationList() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.DestinationFlag) { await this.sleep(1000) }
      this.$refs.destination.loadData()
    },
    async loadCurrencyList() {
      this.setOverlayStatus(true)
      /* eslint-disable-next-line */
      while (!this.CurrencyFlag) { await this.sleep(1000) }
      this.$refs.currency.loadData()
    },
  },
}
</script>

<style scoped>
.selectedTab {
  background-color: #fff;
}
.v-tabs-bar {
  border-radius: revert !important;
}
</style>
