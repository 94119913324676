<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiDelete }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >目的地-削除</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="country_name"
                  readonly
                  label="国"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="selectedDestination.dest_name"
                  readonly
                  label="目的地(英語)"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="selectedDestination.dest_name_cn"
                  readonly
                  label="目的地(中国語)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedDestination.dest_name_jp"
                  readonly
                  label="目的地(日本語)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="deleteItem(selectedDestination.dest_id)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import {
  mdiDelete,
} from '@mdi/js'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    icons: {
      mdiDelete,
    },
    country_name: '',
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,
  }),
  computed: {
    ...mapState('destinationStore', ['selectedDestination']),
    ...mapState('countryStore', ['countryMaster']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('countryStore', ['loadCountryIds']),
    ...mapActions('destinationStore', ['loadDestination', 'deleteDestination']),
    openForm() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCountryIds(),
        this.loadDestination(this.id),
      ]).then(() => {
        this.getCountryName(this.selectedDestination)
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },
    getCountryName(item) {
      this.countryMaster.forEach(a => {
        if (a.value === item.country_id) {
          this.country_name = a.text
        }
      })
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },
    deleteItem(id) {
      this.submitStatus = true
      this.deleteDestination(id)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
          console.log('apierror', this.apierror)
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
  },
}
</script>
