<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiPencil }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
          >目的地-編集</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="$v.Form.country_id.$model"
                  :items="countryMaster"
                  :error-messages="countryIdErrors"
                  hide-details="auto"
                  label="国"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="$v.Form.dest_name.$model"
                  :error-messages="nameErrors"
                  counter="20"
                  hide-details="auto"
                  label="目的地(英語)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.Form.dest_name_cn.$model"
                  :error-messages="nameCnErrors"
                  counter="20"
                  hide-details="auto"
                  label="目的地(中国語)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.Form.dest_name_jp.$model"
                  :error-messages="nameJpErrors"
                  counter="20"
                  hide-details="auto"
                  label="目的地(日本語)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="submit(Form)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import {
  mdiPencil,
} from '@mdi/js'
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    icons: {
      mdiPencil,
    },
    Form: {
      country_id: '',
      dest_name: '',
      dest_name_cn: '',
      dest_name_jp: '',
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,
  }),
  validations: {
    Form: {
      country_id: {
        required,
      },
      dest_name: {
        required,
        maxLength: maxLength(20),
      },
      dest_name_cn: {
        required,
        maxLength: maxLength(20),
      },
      dest_name_jp: {
        required,
        maxLength: maxLength(20),
      },
    },
  },
  computed: {
    ...mapState('countryStore', ['countryMaster']),
    ...mapState('destinationStore', ['selectedDestination']),
    countryIdErrors() {
      const errors = []
      if (!this.$v.Form.country_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.country_id.required && errors.push('必須項目')

      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.Form.dest_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_name.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_name.maxLength && errors.push('20文字以内にしてください。')

      return errors
    },
    nameCnErrors() {
      const errors = []
      if (!this.$v.Form.dest_name_cn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_name_cn.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_name_cn.maxLength && errors.push('20文字以内にしてください。')

      return errors
    },
    nameJpErrors() {
      const errors = []
      if (!this.$v.Form.dest_name_jp.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_name_jp.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_name_jp.maxLength && errors.push('20文字以内にしてください。')

      return errors
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('destinationStore', ['loadDestination', 'editDestination']),
    ...mapActions('countryStore', ['loadCountryIds']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadDestination(this.id).then(() => {
        this.Form = this.$deepCopy(this.selectedDestination)
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },
    closeForm() {
      this.dialog = false
      this.$emit('refresh')
    },
    submit(Form) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.editDestination(Form)
          .then(() => {
            this.closeForm()
          })
          .catch(error => {
            console.log(error)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
            console.log('apierror', this.apierror)
          })
          .finally(() => {
            this.submitStatus = false
          })
      }
    },
  },
}
</script>
