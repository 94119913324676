<template>
  <div justify="center">
    <v-btn
      color="primary"
      class="mr-2"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiPlus }}
      </v-icon>
      <span>新規</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
          >通貨-新規</span>
        </v-card-title>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-card-text>
          <v-container>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="$v.Form.ccy_code.$model"
                  :error-messages="codeErrors"
                  counter="3"
                  hide-details="auto"
                  label="通貨コード"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.Form.ccy_mark.$model"
                  :error-messages="markErrors"
                  counter="3"
                  hide-details="auto"
                  label="通貨マーク"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.Form.ccy_memo.$model"
                  :error-messages="memoErrors"
                  counter="45"
                  hide-details="auto"
                  label="通貨メモ"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="submit(Form)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex'
import {
  mdiPlus,
} from '@mdi/js'
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators'

export default {
  data: () => ({
    dialog: false,
    icons: {
      mdiPlus,
    },
    Form: {
      ccy_code: '',
      ccy_mark: '',
      ccy_memo: '',
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,
  }),
  validations: {
    Form: {
      ccy_code: {
        required,
        maxLength: maxLength(3),
      },
      ccy_mark: {
        required,
        maxLength: maxLength(3),
      },
      ccy_memo: {
        required,
        maxLength: maxLength(45),
      },
    },
  },
  computed: {
    codeErrors() {
      const errors = []
      if (!this.$v.Form.ccy_code.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_code.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_code.maxLength && errors.push('3文字以内にしてください。')

      return errors
    },
    markErrors() {
      const errors = []
      if (!this.$v.Form.ccy_mark.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_mark.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_mark.maxLength && errors.push('3文字以内にしてください。')

      return errors
    },
    memoErrors() {
      const errors = []
      if (!this.$v.Form.ccy_memo.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_memo.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_memo.maxLength && errors.push('45文字以内にしてください。')

      return errors
    },
  },
  methods: {
    ...mapActions('currencyStore', ['createCurrency']),
    openForm() {
      this.dialog = true
    },
    closeForm() {
      this.dialog = false
      this.Form.ccy_code = ''
      this.Form.ccy_mark = ''
      this.Form.ccy_memo = ''
      this.$emit('refresh')
    },
    submit(Form) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createCurrency(Form)
          .then(() => {
            this.closeForm()
          })
          .catch(error => {
            console.log(error)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
            console.log('apierror', this.apierror)
          })
          .finally(() => {
            this.submitStatus = false
          })
      }
    },
  },
}
</script>
