<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <CurrencyNew
        @refresh="loadData"
      ></CurrencyNew>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="currencyList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <CurrencyEdit
              :id="item.ccy_id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </CurrencyEdit>
            <CurrencyDelete
              :id="item.ccy_id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </CurrencyDelete>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'

import CurrencyNew from './CurrencyNew.vue'
import CurrencyEdit from './CurrencyEdit.vue'
import CurrencyDelete from './CurrencyDelete.vue'

export default {
  components: {
    CurrencyNew,
    CurrencyEdit,
    CurrencyDelete,
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'ccy_id',
        width: '10%',
        fixed: true,
      },
      {
        text: '通貨コード',
        align: 'left',
        value: 'ccy_code',
        width: '26%',
        fixed: true,
      },
      {
        text: '通貨マーク',
        align: 'left',
        value: 'ccy_mark',
        width: '26%',
        fixed: true,
      },
      {
        text: '通貨メモ',
        align: 'left',
        value: 'ccy_memo',
        width: '30%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '8%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('currencyStore', ['currencyList']),
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('currencyStore', ['loadCurrencyList']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadCurrencyList(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
