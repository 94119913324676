<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <DestinationNew
        @refresh="loadData"
      ></DestinationNew>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="destinationList"
        :search="search"
      >
        <template v-slot:[`item.country_id`]="{ item }">
          <div>
            <span>{{ getStatu(item.country_id) }}</span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <DestinationEdit
              :id="item.dest_id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </DestinationEdit>
            <DestinationDelete
              :id="item.dest_id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </DestinationDelete>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'
import DestinationNew from './DestinationNew.vue'
import DestinationEdit from './DestinationEdit.vue'
import DestinationDelete from './DestinationDelete.vue'

export default {
  components: {
    DestinationNew,
    DestinationEdit,
    DestinationDelete,
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'dest_id',
        width: '10%',
        fixed: true,
      },
      {
        text: '国',
        align: 'left',
        value: 'country_id',
        width: '20%',
        fixed: true,
      },
      {
        text: '目的地(英語)',
        align: 'left',
        value: 'dest_name',
        width: '22%',
        fixed: true,
      },
      {
        text: '目的地(中国語)',
        value: 'dest_name_cn',
        align: 'left',
        width: '21%',
        fixed: true,
      },
      {
        text: '目的地(日本語)',
        value: 'dest_name_jp',
        align: 'left',
        width: '21%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '6%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('destinationStore', ['destinationList']),
    ...mapState('countryStore', ['countryMaster']),
  },

  created() {
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('destinationStore', ['loadDestinationList']),
    ...mapActions('countryStore', ['loadCountryIds']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCountryIds(),
        this.loadDestinationList(this.$route.query),
      ]).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    getStatu(item) {
      for (let i = 0; i < this.countryMaster.length; i += 1) {
        if (item === this.countryMaster[i].value) {
          return this.countryMaster[i].text
        }
      }

      return ''
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
