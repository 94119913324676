<template>
  <v-row
    class="px-2 my-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-text-field
        v-model="search"
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3"
      >
      </v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <IncotermsNew
        @refresh="loadData"
      ></IncotermsNew>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        :headers="headers"
        :items="incotermList"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <IncotermsEdit
              :id="item.ic_id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </IncotermsEdit>
            <IncotermsDelete
              :id="item.ic_id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </IncotermsDelete>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
} from '@mdi/js'
import IncotermsNew from './IncotermsNew.vue'
import IncotermsEdit from './IncotermsEdit.vue'
import IncotermsDelete from './IncotermsDelete.vue'

export default {
  components: {
    IncotermsNew,
    IncotermsEdit,
    IncotermsDelete,
  },
  data: () => ({
    icon: {
      mdiPlus,
      mdiPencil,
      mdiDelete,
    },
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'ic_id',
        width: '20%',
        fixed: true,
      },
      {
        text: '取引方式コード',
        align: 'left',
        value: 'ic_code',
        width: '30%',
        fixed: true,
      },
      {
        text: '取引方式説明',
        align: 'left',
        value: 'ic_desc',
        width: '45%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('incotermStore', ['incotermList']),
    ...mapState('pelletStore', ['pelletIds']),
  },

  watch: {
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('incotermStore', ['loadIncotermList']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadIncotermList(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
